<template>
  <button
    class="cursor-pointer size-14 ring-offset-1 ring-offset-surface overflow-hidden aspect-square rounded-full border"
    :class="{
      'hover:ring-1 ring-grey-200': !option.isSelected,
      'ring-2 ring-grey-950 pointer-events-none': option.isSelected,
      '': option.isSelected && option.isValid,
      'ring-red border-red': option.isSelected && !option.isValid
    }"
    :title="option.value ?? ''"
    @click="selectOption(option)"
  >
    <span class="size-full grid place-content-center" :style="styles" :class="{ 'opacity-50': !option.isValid }">
      <span v-if="!option.isValid" class="block text-grey">
        <XMarkIcon class="size-8" />
      </span>
    </span>
  </button>
</template>

<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/outline'
import type { ProductOptionDetailed } from '~/types/product'
import { useSwatch } from '~/composables/useSwatch'

// Define props
const props = defineProps<{
  option: ProductOptionDetailed
}>()

const emit = defineEmits(['option-selection'])

const { getStyles } = useSwatch()

/**
 * Emits option-selection event to parent. Detailed option as a payload.
 * @param option
 * @emits option-selection
 */
const selectOption = (option: ProductOptionDetailed) => {
  emit('option-selection', option)
}

const styles = computed(() => {
  const obj = getStyles(props.option)
  return obj as any
})

// const swatch = computed(() => swatchStore.getSwatch(props.option.label, props.option.value))
</script>
