<template>
  <BaseModal content-class="max-w-[560px]" :open="isOpen" @close="isOpen = false">
    <template v-if="isOpen">
      <div class="gamma mb-6" data-jest="title">
        Lisää toivelistalle...
      </div>
      <div class="grid gap-1">
        <BaseButton
          v-for="wl in wishlists"
          :key="wl.id"
          look="ghost"
          data-jest="wishlist-button"
          type="button"
          class="group justify-start"
          @click="addToWishlist(wl)"
        >
          <HeartIcon aria-hidden="true" class="size-6 group-focus:fill-red-800 group-focus:stroke-red-800" />{{ wl.name }}
        </BaseButton>
        <BaseButton
          look="link"
          data-jest="new-wishlist-button"
          type="button"
          class="justify-start"
          @click="openCreateMenu"
        >
          <PlusIcon aria-hidden="true" class="size-6" />Uusi toivelista
        </BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { HeartIcon, PlusIcon } from '@heroicons/vue/24/outline'
import type { Wishlist } from '~/types/user'
import BaseModal from '~/components/BaseModal.vue'
import type { ProductAlgolia } from '~/types/product'

const props = defineProps({
  product: {
    type: Object as () => ProductAlgolia,
    required: true
  }
})

const store = useUserStore()
const { $emitter } = useNuxtApp()
const isOpen = ref(false)
const wishlists = computed((): Wishlist[] => store.wishlists)

onMounted(() => {
  $emitter.on('wishlist:open-menu:add-to-one', (open: boolean) => {
    isOpen.value = open
  })

  $emitter.on('wishlist:created', (newWishlist: Wishlist) => {
    if (isOpen.value) {
      addToWishlist(newWishlist)
    }
  })
})

const openCreateMenu = () => {
  $emitter.emit('wishlist:open-menu:create', true)
}

const addToWishlist = async (wishlist: Wishlist) => {
  await store.addItemToWishlist({ wishlistId: wishlist.id, product: props.product })
  isOpen.value = false
}

onBeforeUnmount(() => {
  $emitter.off('wishlist:open-menu:add-to-one')
  $emitter.off('wishlist:created')
})
</script>
