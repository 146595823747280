<template>
  <div>
    <Product v-if="currentProduct" :product="currentProduct" />
  </div>
</template>

<script setup lang="ts">
import { useProductStore } from '~/stores/product'
import type { ProductAlgolia } from '~/types/product'
import Product from '~/components/product/Product.vue'

const productStore = useProductStore()
const route = useRoute()

const currentProduct = computed(() => productStore.currentProduct as ProductAlgolia | null)

// onBeforeMount(async () => {
//   const currentProduct: ProductAlgolia | null = productStore.currentProduct
if (currentProduct.value?.slug !== route.params.slug) {
  const fetchedProduct = await productStore.fetchCurrentProductBySlug(route.params.slug.toString())
  if (!fetchedProduct) {
    throw createError({
      statusCode: 404,
      statusMessage: 'Product Not Found'
    })
  }
}
// })
</script>
