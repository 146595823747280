<template>
  <div>
    <div class="relative z-1">
      <VDropdown
        :aria-id="ariaId"
        :skidding="-4"
        :placement="'bottom-end'"
        :disabled="isLoggedIn"
        :trigger="'click'"
      >
        <BaseButton
          data-test="add-to-wishlist"
          data-jest="add-to-wishlist"
          :state="isAddedToWishlist ? 'added' : 'default'"
          look="ghost"
          transition="zoom-in"
          class="text-lg w-[3em] p-0"
          :title="isAddedToWishlist ? $t('removeFromWishlist') : $t('addToWishlist')"
          :aria-label="isAddedToWishlist ? $t('removeFromWishlist') : $t('addToWishlist')"
          @click="addToWishlistToggle"
        >
          <template #default>
            <HeartIcon aria-hidden="true" class="size-8" />
          </template>
          <template #added>
            <HeartIcon aria-hidden="true" class="size-8 fill-red-800 stroke-red-800" />
          </template>
        </BaseButton>
        <template #popper>
          <div class="p-3">
            <NuxtLink to="/kirjaudu">Kirjaudu sisään</NuxtLink> käyttääksesi toivelistaa.
          </div>
        </template>
      </VDropdown>
    </div>

    <WishlistMenuAddToOne v-if="product" :product="product" />
    <WishlistMenuAddToMany v-if="product" :product="product" />
    <WishlistMenuCreate />
  </div>
</template>

<script setup lang="ts">
import { HeartIcon } from '@heroicons/vue/24/outline'
import WishlistMenuAddToOne from '~/components/wishlist/menus/WishlistMenuAddToOne.vue'
import WishlistMenuCreate from '~/components/wishlist/menus/WishlistMenuCreate.vue'
import WishlistMenuAddToMany from '~/components/wishlist/menus/WishlistMenuAddToMany.vue'
import type { Wishlist } from '~/types/user'
import type { WishlistItem } from '~/types/item'

const { $emitter } = useNuxtApp()
const productStore = useProductStore()
const product = computed(() => productStore.currentProduct)
const productSku = computed(() => product.value!.sku)
const userStore = useUserStore()
const { wishlists, loggedIn: isLoggedIn } = storeToRefs(userStore)
const isAddedToWishlist = computed((): boolean => wishlists.value?.some((wl: Wishlist) => wl.items?.some((item: WishlistItem) => item.id === productSku.value)))
const isAddedToFirstWishlist = computed((): boolean => wishlists.value[0]?.items?.some((item: WishlistItem) => item.id === productSku.value))
const ariaId = useId()

const addToWishlistToggle = async () => {
  if (!isLoggedIn.value) return
  if (wishlists.value.length <= 1) {
    const firstWishlist = wishlists.value[0]
    if (!isAddedToFirstWishlist.value) {
      await userStore.addItemToWishlist({ wishlistId: wishlists.value[0]?.id, product: product.value! })
    }
    else {
      const item = firstWishlist.items.find(item => item.id === productSku.value)
      if (item) {
        // Remove item from wishlist in store
        await userStore.removeItemFromWishlist({ wishlistId: firstWishlist.id, item })
      }
    }
  }
  else if (isAddedToWishlist.value) {
    $emitter.emit('wishlist:open-menu:add-to-many', true)
  }
  else {
    $emitter.emit('wishlist:open-menu:add-to-one', true)
  }
}
</script>
