<template>
  <dl class="details-list">
    <template v-for="(unit, i) in measurements" :key="i">
      <!-- <dt :key="i+'dt'"> -->
      <dt>
        {{ unit.label }}
      </dt>
      <!-- <dd :key="i+'dd'"> -->
      <dd>
        {{ parseValue(unit.value) }}
      </dd>
    </template>
  </dl>
</template>

<script setup lang="ts">
import type { UnitObject } from '~/types/product'

defineProps({
  measurements: {
    type: Object as () => UnitObject,
    required: true
  }
})

const parseValue = (originalValues: any) => {
  // if its array, parse string out of labels
  if (typeof originalValues === 'object' && Array.isArray(originalValues)) {
    const values: string[] = []
    originalValues.forEach(originalValue => values.push(originalValue.label))
    return values.join(', ')
  }
  if (typeof originalValues === 'boolean') {
    return originalValues ? 'Kyllä' : 'Ei'
  }
  originalValues = (originalValues === 'Yes') ? 'Kyllä' : originalValues
  originalValues = (originalValues === 'No') ? 'Ei' : originalValues
  return originalValues
}
</script>
