<template>
  <div :class="cssClasses">
    <div class="relative">
      <NuxtLink class="text-grey-950 hover:text-grey-950 hover:no-underline" :to="'/product/'+product.slug">
        <img
          :src="getMainImageUrl(product, 'thumbnail')"
          loading="lazy"
          :alt="product.name.variation"
          class="product-image rounded size-20 sm:size-24 aspect-square object-contain bg-white group-hover:brightness-105 transition-[filter] duration-200"
        >
      </NuxtLink>
    </div>

    <div class="flex-1">
      <div class="flex flex-col flex-1 gap-3 h-full">
        <strong class="font-semibold text-sm md:text-base flex-1 line-clamp-2 overflow-ellipsis">
          <NuxtLink class="text-grey-950 hover:text-grey-950" :to="'/product/'+product.slug">
            {{ product.name.default }}
          </NuxtLink>
        </strong>
        <div class="flex gap-4 items-end">
          <div class="flex-1 grid gap-3">
            <div class="grid gap-1">
              <StockStatus v-if="product.stock" :item="product" class="text-xs" />
              <SupplierStockStatus v-if="product.stock.supplierAvailability !== undefined" :is-available="product.stock.supplierAvailability.isAvailable" class="text-xs" />
            </div>

            <ProductPriceDisplay
              class="text-sm"
              :final-price="product.finalPrice"
              :price="product.price"
            />
          </div>
          <div class="relative">
            <BaseButton
              :state="cartButtonStatus"
              look="transactional"
              class="size-12"
              @click="addToCart"
            >
              <template #default>
                <ShoppingCartIcon class="size-6" />
              </template>

              <template #error>
                <XMarkIcon class="size-6" />
              </template>
            </BaseButton>
            <ClientOnly>
              <Transition name="bounce">
                <span
                  :key="amountInCart"
                  :class="{ hidden: amountInCart < 1 }"
                  class="ring-2 ring-surface px-0.5 min-w-5 h-5 absolute -top-2 -right-2 bg-red-600 text-white rounded-full text-xs font-semibold grid place-items-center"
                  :aria-label="$t('amountInCart', amountInCart)"
                  :title="$t('amountInCart', amountInCart)"
                >
                  {{ amountInCart }}
                </span>
              </Transition>
            </ClientOnly>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { twMerge } from 'tailwind-merge'
import { XMarkIcon, ShoppingCartIcon } from '@heroicons/vue/24/outline'
import { useCartStore } from '~/stores/cart'
import type { ProductTileAlgolia } from '~/types/product'
import StockStatus from '~/components/product/StockStatus.vue'
import SupplierStockStatus from '~/components/product/SupplierStockStatus.vue'

const props = defineProps({
  product: {
    type: Object as () => ProductTileAlgolia,
    required: true
  }
})

const cartStore = useCartStore()

const attrs = useAttrs()

const { getMainImageUrl } = useProduct()

const cartButtonStatus = ref('default')

const addToCart = async () => {
  const isAdded = await cartStore.addToCartBySku(props.product.sku, { notifyUser: false, pushGtm: false })

  if (!isAdded) {
    cartButtonStatus.value = 'error'
    setTimeout(() => {
      cartButtonStatus.value = 'default'
    }, 2000)
  }
}

const amountInCart = computed(() => cartStore.getCartItemBySku(props.product.sku)?.amount || 0)

const cssClasses: ComputedRef<string> = computed(() => twMerge('@container p-3 h-full flex gap-3 group rounded bg-surface', attrs.class as string))
</script>
