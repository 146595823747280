<template>
  <button
    class="cursor-pointer px-4 py-2 rounded border min-w-24"
    :class="{
      'bg-grey-200 text-muted italic bg-surface hover:bg-surface': !option.isValid,
      'bg-surface-raised hover:bg-grey-50': !option.isSelected,
      'bg-grey-950 border-grey-950 text-grey-50 pointer-events-none': option.isSelected,
      '': option.isSelected && option.isValid,
      'ring-red ring-2 ring-offset-1': option.isSelected && !option.isValid
    }"
    @click="selectOption(option)"
  >
    {{ option.value }}
  </button>
</template>

<script setup lang="ts">
import type { ProductOptionDetailed } from '~/types/product'

defineProps<{
  option: ProductOptionDetailed
}>()

const emit = defineEmits(['option-selection'])

/**
 * Emits option-selection event to parent. Detailed option as a payload.
 * @param option
 * @emits option-selection
 */
const selectOption = (option: ProductOptionDetailed) => {
  emit('option-selection', option)
}
</script>
