<template>
  <BaseModal content-class="max-w-[560px]" :open="isOpen" @close="isOpen = false">
    <template v-if="isOpen">
      <div class="gamma mb-6" data-jest="title">
        Lisää tai poista tuote...
      </div>
      <div class="grid gap-2">
        <form
          class="grid gap-2"
          novalidate
          @submit.prevent="saveSelections"
        >
          <BaseCheckboxGroup
            v-model="selections"
            :options="options"
            name="select-wishlists"
            class="gap-1"
          />
          <div>
            <BaseButton
              data-jest="new-wishlist-button"
              type="button"
              look="link"
              class="px-0"
              @click="openCreateMenu"
            >
              <PlusIcon aria-hidden="true" class="size-6" />Uusi toivelista
            </BaseButton>
            <div>
              <BaseButton
                :state="isSaving ? 'loading' : 'default'"
                type="submit"
                data-jest="submit"
                class="w-full sm:w-auto mt-4"
              >
                <template #default>
                  Tallenna
                </template>
                <template #loading>
                  <EllipsisLoadingIcon />
                </template>
              </BaseButton>
            </div>
          </div>
        </form>
      </div>
    </template>
  </BaseModal>
</template>

<script setup lang="ts">
import { PlusIcon } from '@heroicons/vue/24/outline'
import BaseButton from '~/components/BaseButton.vue'
import BaseCheckboxGroup from '~/components/BaseCheckboxGroup.vue'
import type { Wishlist } from '~/types/user'
import { EllipsisLoadingIcon } from '~/components/icon'
import BaseModal from '~/components/BaseModal.vue'
import type { ProductAlgolia } from '~/types/product'
import { useCustomization } from '~/composables/useCustomization'

const props = defineProps({
  product: {
    type: Object as () => ProductAlgolia,
    required: true
  }
})
const store = useUserStore()
const { wishlists } = storeToRefs(useUserStore())
const { getProductId } = useCustomization()
const { $emitter } = useNuxtApp()
const isOpen = ref(false)
const isSaving = ref(false)
const selections = ref<string[]>([])

const productInWishlists = computed(() => {
  return wishlists.value?.filter((wl: Wishlist) => wl.items.some(item => item.id === getProductId(props.product))).map((wl: Wishlist) => wl.id) || []
})

onMounted(() => {
  $emitter.on('wishlist:open-menu:add-to-many', (open: boolean) => {
    selections.value = productInWishlists.value as string[]
    isOpen.value = open
  })
})

const openCreateMenu = () => {
  $emitter.emit('wishlist:open-menu:create', true)
}

const saveSelections = async () => {
  isSaving.value = true
  const isSuccessfulUpdate = await store.updateItemInWishlists({ wishlistIds: selections.value, product: props.product })
  isOpen.value = !isSuccessfulUpdate
  isSaving.value = false
}

const options = computed(() => wishlists.value.map(wl => ({ id: wl.id as string, label: wl.name, value: wl.id as string })))

onBeforeUnmount(() => {
  $emitter.off('wishlist:open-menu:add-to-many')
})
</script>
