<template>
  <div>
    <div
      v-if="widgetClass === 'yotpo-main-widget'"
      ref="yotpo"
      class="yotpo yotpo-main-widget pb-px"
      :data-product-id="product.itemId"
      :data-price="product.finalPrice.inclTax"
      data-currency="EUR"
      :data-name="product.name.default"
      :data-url="'/' + product.slug"
      :data-image-url="getMainImageUrl(product)"
    />
    <div v-if="widgetClass === 'bottomLine'" class="grid gap-3 text-0">
      <div>
        <div class="yotpo bottomLine inline-block" :data-yotpo-product-id="product.itemId" />
      </div>
      <!-- Commented out section -->
      <!--
      <div v-if="false">
        <div class="yotpo QABottomLine yotpo-small inline-block" :data-appkey="appKey" :data-product-id="product.itemId" />
      </div>
      -->
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ProductAlgolia } from '~/types/product'
import { useProduct } from '~/composables/useProduct'
import { useProductStore } from '~/stores/product'

defineProps({
  widgetClass: { type: String, required: false, default: 'yotpo-main-widget' }
})

const productStore = useProductStore()
const { getMainImageUrl } = useProduct()
const appKey = useRuntimeConfig().public.YOTPO_API_KEY as string // Assuming VITE_YOTPO_API_KEY is defined in .env
const product = computed(() => productStore.currentProduct as ProductAlgolia)
// const yotpo = ref<any>(null)

useHead(
  {
    script: [
      {
        hid: 'yotpo',
        type: 'text/javascript',
        src: `https://staticw2.yotpo.com/${appKey}/widget.js`,
        defer: true
      }
    ]
  }
)

onMounted(() => {
  // @ts-ignore
  if (typeof window.yotpo !== 'undefined') {
    // @ts-ignore
    window.yotpo.refreshWidgets()
  }
})

// Removed the following style:
/*
.yotpo.QABottomLine a,
.yotpo.bottomLine a {
  @apply font-body text-red-800 hover:text-rose !important;
}
*/
</script>

<style lang="scss">
.yotpo.yotpo-main-widget:empty {
  min-height: 70vh;
  @apply bg-white animate-fadepulse;
}
.yotpo.QABottomLine:empty,
.yotpo.bottomLine:empty {
  @apply bg-white animate-fadepulse h-5 min-w-48;
}
.yotpo .standalone-bottomline {
  .star-clickable {
    @apply gap-2 items-center;
  }
  .yotpo-bottomline.pull-left {
    @apply flex gap-2 items-center float-none;
    .yotpo-icon.yotpo-icon-double-bubble {
      @apply transform translate-y-0.5 text-lg leading-none;
    }
  }
}
</style>
