<template>
  <div class="w-full sm:w-auto relative">
    <BaseButton
      data-test="add-to-cart"
      data-jest="add-to-cart"
      look="transactional"
      class="w-full text-lg"
      :class="{ disabled: !isEnabled }"
      :disabled="!isEnabled"
      @click="addToCart"
    >
      <ShoppingCartIcon class="size-6" aria-hidden="true" />{{ $t('addToCart') }}
    </BaseButton>
    <ClientOnly>
      <span
        :class="{ hidden: amountInCart < 1 }"
        class="ring-2 ring-surface w-6 h-6 absolute -top-2 -right-3 bg-red-600 text-white rounded-full text-xs font-semibold grid place-items-center"
        :aria-label="$t('amountInCart', amountInCart)"
        :title="$t('amountInCart', amountInCart)"
      >
        {{ amountInCart }}
      </span>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { ShoppingCartIcon } from '@heroicons/vue/24/outline'
import BaseButton from '~/components/BaseButton.vue'
import { useCartStore } from '~/stores/cart'
// import { useUserStore } from '~/stores/user'
import { useProductStore } from '~/stores/product'

const props = defineProps({
  canAddToCart: {
    type: Boolean,
    required: true
  }
})

const productStore = useProductStore()
const cartStore = useCartStore()
// const { $config, $algoliaInsights } = useNuxtApp()

const product = computed(() => productStore.currentProduct)
const productAmount = ref(1)

const isSaleable = computed(() => productAmount.value > 0 && Number.isInteger(productAmount.value) && (product.value?.stock?.stockStatus === 'inStock' || product.value?.stock?.stockStatus === 'backorder'))
// const hasCustomizationError = computed(() => productStore.hasCustomizationError
const amountInCart = computed(() => cartStore.getCartItemBySku(product.value?.sku ?? '')?.amount || 0)
const isMaxAmountReached = computed(() => product.value && product.value.productLine === 2 && amountInCart.value >= product.value.stock?.qty)
const isEnabled = computed(() => props.canAddToCart && isSaleable.value && !isMaxAmountReached.value)

const addToCart = () => {
  if (product.value) {
    const gtm = useGtm()
    // send gtm event
    const { convertAddToCartToGtmProduct } = useProduct()
    const gtmProduct = convertAddToCartToGtmProduct(product.value)
    gtm!.trackEvent({
      event: 'add_to_cart',
      ecommerce: {
        items: [gtmProduct]
      }
    })
    cartStore.addToCart(product.value, { amount: productAmount.value, referrer: 'productPage' })
  }

  // const eventType = product.value.__queryID ? 'convertedObjectIDsAfterSearch' : 'convertedObjectIDs'
  // const eventName = product.value.__queryID ? 'product_added_to_cart_after_search' : 'product_added_to_cart'
  // const eventObject = {
  //   userToken: store.getters[UserGetters.loggedIn] ? 'logged_in_user' : 'guest_user',
  //   index: $config.ALGOLIA_INDEX,
  //   eventName,
  //   queryID: eventType === 'convertedObjectIDsAfterSearch' ? product.value.__queryID : undefined,
  //   objectIDs: [product.value.objectID]
  // }
  // $algoliaInsights(eventType, eventObject)
}
</script>
