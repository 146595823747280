<template>
  <section class="bg-surface overflow-y-auto">
    <BaseButton
      look="ghost"
      class="p-0 size-[3em] fixed top-4 right-4 z-40"
      :aria-label="$t('close')"
      @click="close"
    >
      <XMarkIcon class="size-6" aria-hidden="true" />
    </BaseButton>
    <div class="grid gap-6 py-6 text-center md:container">
      <h2 class="beta">
        {{ $t('imagesFromCustomers') }}
      </h2>
      <div class="grid md:grid-cols-2 gap-6">
        <div v-for="reviewImage in currentProductReviewImages" :key="reviewImage.reviewId">
          <img
            :src="reviewImage.originalUrl"
            class="w-full select-none"
            loading="lazy"
            :alt="reviewImage.title"
          >
          <div class="bg-grey-50 p-4 md:p-8 text-left">
            <ReviewStars :score="parseInt(reviewImage.score)" class="h-4 mb-4" />
            <div class="prose">
              <p>
                <strong>{{ reviewImage.title }}</strong>
              </p>
              <p>
                {{ decodeContent(reviewImage.content) }}
              </p>
              <p class="text-secondary">
                {{ reviewImage.userName }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <BaseButton look="outline" @click="close">
          <ChevronLeftIcon aria-hidden="true" class="size-6" /><span>{{ $t('backToProduct') }}</span>
        </BaseButton>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { XMarkIcon, ChevronLeftIcon } from '@heroicons/vue/24/outline'
import { useProductStore } from '~/stores/product'
import ReviewStars from '~/components/product/ReviewStars.vue'

const { currentProductReviewImages } = storeToRefs(useProductStore())
const { $emitter } = useNuxtApp()
const close = () => {
  $emitter.emit('reviewimages:open', false)
}
const decodeContent = (content: string) => {
  if (document) {
    // https://tertiumnon.medium.com/js-how-to-decode-html-entities-8ea807a140e5
    // https://stackoverflow.com/questions/7394748/whats-the-right-way-to-decode-a-string-that-has-special-html-entities-in-it
    const txt = document.createElement('textarea')
    txt.innerHTML = content
    return txt.value
  }

  return content
}
</script>
