<template>
  <div>
    <template v-if="variationProducts.length > 0">
      <template v-if="optionSets.length > 0">
        <div class="grid gap-6">
          <OptionSet
            v-for="optionSet in optionSets"
            :key="optionSet.attributeLabel"
            :option-set="optionSet"
            @option-selection="selectOption"
          />
          <div v-if="variationNotFound" data-jest="variation-not-available" class="text-red text-sm">
            Tätä variaatiota ei valitettavasti ole saatavilla.
          </div>
        </div>
      </template>
    </template>
    <template v-else>
      <div class="grid gap-6 animate-fadepulse" aria-hidden="true">
        <div v-for="selectableVariation in product.selectableVariationAttributes" :key="selectableVariation.label">
          <div class="bg-gradient-to-r from-grey-200 to-transparent w-24 rounded mb-4">
            &nbsp;
          </div>
          <div class="flex gap-4 flex-wrap">
            <template v-if="isImageOption(selectableVariation.label)">
              <div v-for="i in 3" :key="i" class="size-14 rounded-full bg-grey-200" />
            </template>
            <template v-else>
              <div v-for="i in 2" :key="i" class="bg-grey-200 border rounded px-4 py-2 w-28">
                &nbsp;
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useProductStore } from '~/stores/product'
import { useProductOption } from '~/composables/useProductOption'
import OptionSet from '~/components/product/product-options/OptionSet.vue'
import type { ProductAlgolia, ProductOptionSet, ProductOption, ProductOptionDetailed } from '~/types/product'

const props = defineProps<{
  product: ProductAlgolia
}>()

const emit = defineEmits(['option-change'])

const productStore = useProductStore()
// const { convertProductToGtmProduct, mapMatomoProductViewData } = useProduct()
// const { $gtm, $matomo } = useNuxtApp()
const { isImageOption } = useProductOption()

onMounted(() => {
  productStore.fetchCurrentProductVariations()
})
onUnmounted(() => {
  productStore.removeCurrentProductVariations()
})

const variationProducts = computed(() => productStore.currentProductVariations)

const selectedOptions = ref<ProductOption[]>(props.product.selectableVariationAttributes || [])

const selectOption = ({ label, value }: ProductOptionDetailed) => {
  selectedOptions.value = [...selectedOptions.value.filter(s => s.label !== label), { label, value }]
}

const getProductVariationsBySelections = (ignoredAttributeLabel?: string) =>
  variationProducts.value.filter((p) => {
    const options = p.selectableVariationAttributes || []
    const stringifiedOptions = options.map(sva => `${sva.label}~${sva.value}`)
    return selectedOptions.value
      .filter(s => s.label !== ignoredAttributeLabel)
      .every(s => stringifiedOptions.includes(`${s.label}~${s.value || ''}`))
  })

const getUniqueOptionsByProductList = (products: ProductAlgolia[]) =>
  products.flatMap(p => p.selectableVariationAttributes || [])
    .reduce((acc: ProductOption[], option) => {
      if (!acc.find(o => o.label === option.label && o.value === option.value)) {
        acc.push(option)
      }
      return acc
    }, [])

const uniqueOptions = computed(() => getUniqueOptionsByProductList(variationProducts.value))

const uniqueAttributeLabels = computed(() => [...new Set(uniqueOptions.value.map(s => s.label))])

const getValidOptions = (attributeLabel: string) => {
  const productList = getProductVariationsBySelections(attributeLabel)
  return getUniqueOptionsByProductList(productList)
}

const getDetailedOptionsByAttribute = (attributeLabel: string) => {
  const allOptions = uniqueOptions.value.filter(s => s.label === attributeLabel)
  const validOptions = getValidOptions(attributeLabel).filter(s => s.label === attributeLabel).map(s => s.value)
  return allOptions.map(option => ({
    value: option.value,
    colors: [option.primaryColorHex, option.secondaryColorHex, option.tertiaryColorHex].filter(Boolean) as string[],
    image: option.swatchImage || null,
    label: attributeLabel,
    isSelected: selectedOptions.value.some(cs => cs.value === option.value && cs.label === attributeLabel),
    isValid: validOptions.includes(option.value)
  }))
}

const optionSets: ComputedRef<ProductOptionSet[]> = computed(() => uniqueAttributeLabels.value.map(attributeLabel => ({
  attributeLabel,
  options: getDetailedOptionsByAttribute(attributeLabel)
})))

const variationNotFound = computed(() => !matchingVariationProduct.value)

const matchingVariationProduct = computed(() => {
  const matchingProducts = getProductVariationsBySelections()
  return (matchingProducts.length === 1) ? matchingProducts[0] : null
})

watch(matchingVariationProduct, async (matchingProduct) => {
  emit('option-change', !!matchingProduct)
  if (matchingProduct && matchingProduct.sku !== props.product.sku) {
    await productStore.setCurrentProduct(matchingProduct)
    history.replaceState(history.state, '', `/product/${matchingProduct.slug}`)
    await new Promise(resolve => setTimeout(resolve, 0))
    // const gtmProduct = convertProductToGtmProduct(matchingProduct, store)
    // $gtm.push({ ecommerce: null })
    // $gtm.push({ event: 'productDetail', productViewType: 'variantChange', ecommerce: { detail: { products: [gtmProduct] } } })
    // $matomo.trackProductView(
    //   mapMatomoProductViewData(props.product, store.getters['breadcrumb/current']),
    //   `/product/${matchingProduct.slug}`
    // )
  }
})
</script>
