<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="iconSize"
    :height="iconSize"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="1.5"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="veke-icon feather feather-zoom-in"
  >
    <circle cx="11" cy="11" r="8" /><line
      x1="21"
      y1="21"
      x2="16.65"
      y2="16.65"
    /><line
      x1="11"
      y1="8"
      x2="11"
      y2="14"
    /><line
      x1="8"
      y1="11"
      x2="14"
      y2="11"
    />
  </svg>
</template>

<script lang="ts">
import Icon from './Icon'

export default Icon('ZoomInIcon')
</script>
