<template>
  <dl class="details-list">
    <template v-for="(unit, i) in productInfo" :key="i">
      <dt>
        {{ unit.label }}
      </dt>
      <dd>
        <div class="max-w-prose">
          {{ parseValue(unit.value) }}
        </div>
      </dd>
    </template>
    <dt>Tuotekoodi</dt>
    <dd>
      {{ product.sku }}
    </dd>
  </dl>
</template>

<script setup lang="ts">
import type { ProductAlgolia } from '~/types/product'

const props = defineProps<{
  product: ProductAlgolia
}>()

const productInfo = computed(() => {
  const data = {
    ...props.product.technicalSpecifications,
    ...props.product.materialAndColor,
    ...props.product.structure
  }
  delete data.itemColorGroup
  return data
})

const parseValue = (originalValues: any) => {
  if (typeof originalValues === 'object' && Array.isArray(originalValues)) {
    return originalValues.join(', ')
  }
  if (typeof originalValues === 'boolean') {
    return originalValues ? 'Kyllä' : 'Ei'
  }
  originalValues = originalValues === 'Yes' ? 'Kyllä' : originalValues
  originalValues = originalValues === 'No' ? 'Ei' : originalValues
  return originalValues
}
</script>
