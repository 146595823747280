<template>
  <div class="media-gallery grid gap-4">
    <div class="min-w-0 relative bg-white border-y lg:border-none lg:rounded overflow-hidden">
      <ProductDiscountBadge :discount-percent="discountPercent" class="absolute top-4 right-4 z-10" />
      <ClientOnly>
        <Swiper
          :modules="[SwiperNavigation, SwiperPagination, SwiperThumbs]"
          :slides-per-group="1"
          :slides-per-view="1"
          :space-between="0"
          :rewind="true"
          navigation
          pagination
          :thumbs="{ swiper: thumbsSwiper }"
        >
          <SwiperSlide v-for="image in images" :key="'main-slide-'+ image.url">
            <img
              :src="getUrlFromImage(image, 'large')"
              class="aspect-square max-h-[65vh] lg:max-h-none w-full object-contain select-none"
              :alt="image.alt || 'Tuotekuva'"
              loading="lazy"
            >
          </SwiperSlide>
        </Swiper>
        <template #placeholder>
          <img
            v-if="images.length > 0"
            :src="serverImageUrl"
            :alt="images[0].alt"
            class="aspect-square max-h-[65vh] lg:max-h-none w-full object-contain select-none"
            fetchpriority="high"
          >
        </template>
      </ClientOnly>
    </div>
    <div class="py-2 md:py-0 flex gap-3 lg:gap-4 text-xs md:text-sm items-center overflow-auto max-w-full px-container lg:px-0 mx-auto">
      <button v-if="images.length" class="bg-surface-raised whitespace-nowrap leading-none hover:border-grey-950 flex items-center gap-2 rounded-full px-3 border py-1.5" @click="openLightBox">
        <ZoomInIcon size="18" />
        <span>{{ $t('largeImages') }}</span>
      </button>
      <button v-if="pixmoorUrl" class="bg-surface-raised whitespace-nowrap leading-none hover:border-grey-950 flex items-center gap-2 rounded-full px-3 border py-1.5" @click="openPixmoorModal">
        <Rotate3dIcon size="18" />
        <span>{{ $t('threeDImage') }}</span>
      </button>
      <button v-if="hasVideo" class="bg-surface-raised whitespace-nowrap leading-none hover:border-grey-950 flex items-center gap-2 rounded-full px-3 border py-1.5" @click="openVideoModal">
        <YoutubeIcon size="18" />
        <span>{{ $t('video') }}</span>
      </button>
      <button v-if="hasReviewImages" class="bg-surface-raised whitespace-nowrap leading-none hover:border-grey-950 flex items-center gap-2 rounded-full px-3 border py-1.5" @click="openReviewImages">
        <CameraIcon size="18" />
        <span>{{ $t('imagesFromCustomers') }}</span>
      </button>
    </div>
    <div class="min-w-0 hidden lg:block">
      <ClientOnly>
        <Swiper
          ref="thumbsSwiper"
          :modules="[SwiperNavigation, SwiperThumbs]"
          :slides-per-group="4"
          :slides-per-view="4"
          :space-between="12"
          :center-insufficient-slides="true"
          :rewind="true"
          navigation
          :breakpoints="{
            768: {
              slidesPerGroup: 6,
              slidesPerView: 6
            }
          }"
          class="media-gallery-thumbs"
          @swiper="setThumbsSwiper"
        >
          <SwiperSlide v-for="image in images" :key="image.url">
            <img
              :src="getUrlFromImage(image, 'thumbnail')"
              :alt="image.alt"
              loading="lazy"
              class="w-full h-full bg-white object-contain aspect-square"
            >
          </SwiperSlide>
        </Swiper>
        <template #placeholder>
          <div class="flex gap-3">
            <div v-for="i in 6" :key="i" class="[&:nth-child(5)]:hidden [&:nth-child(6)]:hidden md:[&:nth-child(5)]:block md:[&:nth-child(6)]:block w-full bg-grey-100 animate-fadepulse aspect-square" />
          </div>
        </template>
      </ClientOnly>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ProductAlgolia } from '~/types/product'
import { getUrlFromImage } from '~/helpers/product'
import { ZoomInIcon, Rotate3dIcon, YoutubeIcon, CameraIcon } from '~/components/veke-ui/icons'
import ProductDiscountBadge from '~/components/product/ProductDiscountBadge.vue'

const thumbsSwiper = ref(null)

const setThumbsSwiper = (sw: any) => {
  thumbsSwiper.value = sw
}

interface Props {
  discountPercent: number
  labels: ProductAlgolia['labels']
  images: ProductAlgolia['images']
  pixmoorUrl?: ProductAlgolia['pixmoorUrl']
  hasVideo: boolean
  hasReviewImages: boolean
}

const props = defineProps<Props>()

const serverImageUrl = computed(() => props.images ? getUrlFromImage(props.images[0], 'large') : '')

const { $emitter } = useNuxtApp()

const openLightBox = () => $emitter.emit('lightbox:open', true)
const openPixmoorModal = () => $emitter.emit('pixmoor-image:open', true)
const openVideoModal = () => $emitter.emit('video-modal:open', true)
const openReviewImages = () => $emitter.emit('reviewimages:open', true)
</script>

<style lang="scss">
.media-gallery {
  .swiper:hover {
    .swiper-button-prev,
    .swiper-button-next {
      @apply bg-white dark:text-grey-50 shadow-md hover:bg-grey-50 dark:hover:bg-grey-950;
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    @apply bg-transparent text-default dark:text-grey-50 shadow-none transition-[background,_box-shadow] duration-200;
  }
  .swiper-pagination {
    @apply lg:hidden;
    .swiper-pagination-bullet {
      @apply bg-grey-950 dark:bg-grey-50;
    }
    .swiper-pagination-bullet-active {
      @apply bg-red-800;
    }
  }
  .media-gallery-thumbs {
    --swiper-navigation-size: 24px;
    --swiper-navigation-arrow-size: 12px;
    .swiper-wrapper .swiper-slide {
      @apply rounded *:rounded-[3px] overflow-hidden border *:border *:border-transparent;
      &.swiper-slide-thumb-active {
        @apply border-grey-950 *:border-grey-950 dark:border-red-800 dark:*:border-red-800;
      }
    }
  }
}
</style>
