<template>
  <div :id="id" class="base-accordion" :class="[accordionClass, open && openWrapperClass, !open && closedWrapperClass, { open }]">
    <button
      type="button"
      :aria-expanded="open ? 'true' : 'false'"
      aria-haspopup="true"
      class="accordion-button"
      :class="[buttonClass, open && openButtonClass]"
      @click="toggle"
    >
      <span class="accordion-label">
        <slot name="button">
          {{ label }}
        </slot>
      </span>
      <slot name="icon">
        <ChevronDownIcon size="1.5x" class="chevron-icon" aria-hidden="true" />
      </slot>
    </button>
    <Transition name="slide-y">
      <div
        v-if="open"
        class="accordion-content"
        role="region"
        :aria-label="label"
      >
        <div>
          <div :class="contentClass">
            <slot />
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { ChevronDownIcon } from '~/components/veke-ui/icons'

defineProps({
  id: {
    type: String,
    required: true
  },
  label: {
    type: String,
    required: false,
    default: ''
  },
  accordionClass: {
    type: [Object, String, Array],
    required: false
  },
  closedWrapperClass: {
    type: [Object, String, Array],
    required: false
  },
  openWrapperClass: {
    type: [Object, String, Array],
    required: false
  },
  buttonClass: {
    type: [Object, String, Array],
    required: false
  },
  openButtonClass: {
    type: [Object, String, Array],
    required: false
  },
  contentClass: {
    type: [Object, String, Array],
    required: false
  }
})

const emit = defineEmits(['toggle'])

const open = ref(false)

const toggle = () => {
  open.value = !open.value
  emit('toggle', open.value)
}
defineExpose({ open })
</script>

<style lang="scss">
.base-accordion {
  --duration: 500ms;

  &:has(.accordion-button:focus-visible) {
    outline: auto;
  }

  .accordion-button {
    @apply w-full flex text-left justify-between items-center focus:outline-none;
    .accordion-label {
      @apply flex-1 min-w-0;
    }
    .chevron-icon {
      transition: transform var(--duration) ease-out;
    }
  }
  .accordion-content {
    display: grid;
    grid-template-rows: 1fr;
    align-items: start;
    overflow: hidden;
    > div {
      overflow: hidden;
    }
    &.slide-y {
      &-enter,
      &-enter-from,
      &-leave-to {
        grid-template-rows: 0fr;
      }
      &-enter-active,
      &-leave-active {
        transition: grid-template-rows var(--duration) ease-out;
      }
    }
  }

  &.open {
    .accordion-button {
      .chevron-icon {
        transform: rotate(-180deg);
      }
    }
  }
}
</style>
