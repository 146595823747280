<template>
  <div class="grid grid-flow-row">
    <div v-for="option in options" :key="option.id" class="group flex justify-start">
      <input
        :id="getOptionId(option.id)"
        v-model="model"
        :value="option.value"
        type="checkbox"
        class="sr-only peer"
        :name
      >
      <label :for="getOptionId(option.id)" class="p-3 w-full peer-checked:bg-grey-50 hover:bg-grey-50/50 flex items-center cursor-pointer antialiased leading-none select-none text-sm disabled:pointer-events-none">
        <span class="checkbox inline-grid place-content-center text-white self-start p-1 size-6 border rounded bg-surface-raised group-hover:border-grey-300 transition-colors group-has-[input:not(:checked):disabled]:bg-grey-50 group-has-[input:checked:disabled]:saturate-50 group-has-[input:focus-visible]:border-grey-950 group-has-[input:focus-visible]:ring-1 ring-grey-950 group-has-[input:checked]:bg-red-800 group-has-[input:checked]:border-red-800 overflow-hidden">
          <CheckIcon class="size-5 hidden group-has-[input:checked]:block" stroke-width="4" aria-hidden="true" />
        </span>
        <span v-if="option.label" class="ml-2" v-text="option.label" />
      </label>
    </div>
  </div>
</template>

<script setup lang="ts">
import { CheckIcon } from '@heroicons/vue/24/outline'

defineProps({
  name: {
    type: String,
    required: true,
    default: ''
  },
  options: {
    type: Array as PropType<{ id: string, label: string, value: string }[]>,
    required: true
  },
  modelValue: {
    type: Array,
    required: true
  }
})

const model = defineModel({ type: Array })

const attrs = useAttrs()

const getOptionId = (id: string | number): string => {
  return attrs.id ? `${attrs.id}_${id}` : `${id}`
}
</script>
