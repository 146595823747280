export function useProductOption() {
  /**
   * List of attribute labels, that should be shown as image/color swatches in product page.
   */
  const attributesAsImageSwatches = ['Väri', 'Petauspatja', 'Verhoilu', 'Jalat', 'Rungon väri']

  /**
   * Get option component by option
   */
  const getOptionComponent = (label: string) =>
    attributesAsImageSwatches.includes(label) ? 'image-option' : 'text-option'

  const isImageOption = (label: string) => getOptionComponent(label) === 'image-option'

  return {
    attributesAsImageSwatches,
    getOptionComponent,
    isImageOption
  }
}
