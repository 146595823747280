<template>
  <BaseModal :open="isOpen" @close="closeModal">
    <h3 class="beta mb-4">
      Myymäläsaatavuus
    </h3>
    <p class="text-muted mb-4">
      {{ product.name.variation || product.name.default }}
    </p>
    <div class="[&:has(+div)]:hidden h-[424px] animate-fadepulse bg-grey-50" aria-label="Pieni hetki, myymäläsaatavuuksia ladataan." />
    <LazyStockProductStoreAvailability v-if="isOpen" :product="product" />
  </BaseModal>
</template>

<script setup lang="ts">
import type { ProductAlgolia } from '~/types/product'

defineProps<{
  product: ProductAlgolia
  isOpen: boolean
}>()

const emit = defineEmits(['close'])

const closeModal = () => {
  emit('close')
}
</script>
