<template>
  <div class="prose">
    <div class="delta mb-4 mt-6">
      Matkahuollon Lähellä-paketti alk. 6,00 €
    </div>
    <p>Voit valita toimitustavaksi Matkahuollon Lähellä-paketin, jonka voit noutaa valitsemastasi toimipisteestä. Yksittäisen paketin enimmäispaino on 30 kg. Paketti noudetaan saapumisilmoituksessa ilmoitetusta toimipisteestä noutokoodilla.</p>
    <div class="delta mb-4 mt-6">
      Matkahuollon Jakopaketti alk. 16,10 €
    </div>
    <p>Voit valita toimitustavaksi Matkahuollon Jakopaketin, joka toimitetaan ilmoitettuun osoitteeseen. Yksittäisen paketin enimmäispaino on 55 kg. Paketin toimittaja sopii luovutuksesta tarkemmin vastaanottajan kanssa.</p>
    <div class="delta mb-4 mt-6">
      Postipaketti, nouto lähimmästä Postista
    </div>
    <p>
      Asiakas noutaa tilauksen lähimmästä Postista tai muusta Postin määrittämästä toimipisteestä. Asiakas saa postilta ilmoituksen, kun lähetys on noudettavissa. Posti säilyttää lähetystä 7 vuorokautta, jonka jälkeen lähetys palautuu takaisin lähettäjälle. Mikäli tilaus sisältää useampia tuotteita, Posti voi toimittaa lähetyksen monipakettilähetyksenä. Jokaisesta lähetyksestä muodostuu oma lähetystenseurantatunnus.
    </p>
    <div class="delta mb-4 mt-6">
      Posti Kotipaketti, kuljetus sisälle asuntoon
    </div>
    <p>
      Posti toimittaa asiakkaan tilauksen sisälle asuntoon. Posti on yhteydessä asiakkaaseen toimitusajan sopimiseksi. Kuljetukset tapahtuvat suurimmissa kaupungeissa arkisin klo 8-21, muilla alueilla jakelu klo 14 mennessä. Mikäli tilaus sisältää useampia tuotteita, Posti voi toimittaa lähetyksen monipakettilähetyksenä. Jokaisesta lähetyksestä muodostuu oma lähetystenseurantatunnus.
    </p>
    <div class="delta mb-4 mt-6">
      Posti rahti, kuljetus pihalle (jakeluauton välittömään läheisyyteen)
    </div>
    <p>
      Tilaus toimitetaan pihalle tai parkkipaikalle jakeluauton välittömään läheisyyteen. Toimitusaika ilmoitetaan puhelimitse tai tekstiviestillä. Kuljetukset tapahtuvat arkisin maanantaista perjantaihin pääsääntöisesti klo 8-16 välillä, paikkakuntakohtaisia eroja on.
    </p>
    <div class="delta mb-4 mt-6">
      Posti rahti, kuljetus sisälle asuntoon
    </div>
    <p>
      Posti toimittaa asiakkaan tilauksen sisälle asuntoon. Toimitusaika ilmoitetaan puhelimitse tai tekstiviestillä. Kuljetukset tapahtuvat suurimmissa kaupungeissa arkisin klo 8-21, esim. Helsinki, Rovaniemi, Oulu, Tampere jne. muilla alueilla jakelu klo 16 mennessä. Toimituksissa voi olla paikkakuntakohtaisia eroja. Joillakin postinumeroalueilla (pienemmät paikkakunnat) tilaus toimitetaan yhdellä kuljettajalla.
    </p>
    <div class="delta mb-4 mt-6">
      Nouto myymälästä
    </div>
    <p>Tilauksesi toimitetaan valittuun myymälään noudettavaksi. Toimitusaika myymälään arviolta <strong>0-3 arkipäivää</strong>. Myymälästä ilmoitetaan aina erikseen kun tilaus on noudettavissa. Noudathan tilauksen mahdollisimman pian ilmoituksen saatuasi.</p>
  </div>
</template>

<!-- <script setup lang="ts"></script> -->
