<template>
  <div>
    <template v-for="(brand, i) in brands" :key="brand.name">
      <NuxtLink v-if="brand.slug" :to="'/category/brandit/' + brand.slug">{{ brand.name }}</NuxtLink>
      <span v-else>{{ brand.name }}</span>
      <span v-if="i + 1 < brands.length">, </span>
    </template>
  </div>
</template>

<script setup lang="ts">
import { useCategoryStore } from '~/stores/category'

const props = defineProps({
  manufacturer: {
    type: Array as PropType<Array<string>>,
    required: true,
    validator: (brands: any) => brands.length > 0
  }
})

const brands = computed((): { name: string, slug: string | null }[] => props.manufacturer.map(b => ({ name: b, slug: getCategorySlugByName(b) })))

const categoryStore = useCategoryStore()

const getCategorySlugByName = (name: string): string | null => {
  const category = categoryStore.getCategoryByName(name)
  return category?.slug || ''
}
</script>
