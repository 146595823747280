import type { ProductOptionDetailed } from '~/types/product'
import type { SwatchStyles } from '~/types/swatch'

export function useSwatch() {
  const getStyles = (option: ProductOptionDetailed): SwatchStyles => {
    // @TODO: Should swatch image size be configurable?

    if (!option.colors.length && !option.image) {
      return {
        backgroundImage: 'radial-gradient(circle, rgba(245,245,245,1) 0%, rgba(245,245,245,1) 31%, rgba(255,255,255,1) 31%, rgba(255,255,255,1) 39%, rgba(245,245,245,1) 39%, rgba(245,245,245,1) 100%)'
      }
    }

    const bgImgs: string[] = []

    if (option.image) {
      bgImgs.push('url(\'' + option.image + '\')')
    }
    else if (option.colors.length === 2) {
      bgImgs.push(`linear-gradient(90deg, ${option.colors[0]} 50%, ${option.colors[1]} 50%, ${option.colors[1]} 100%)`)
    }
    else if (option.colors.length === 3) {
      bgImgs.push(`linear-gradient(90deg, ${option.colors[0]} 33%, ${option.colors[1]} 33%, ${option.colors[1]} 66%, ${option.colors[2]} 66%, ${option.colors[2]} 100%)`)
    }

    const styles: SwatchStyles = {}

    if (option.colors[0]) {
      styles.backgroundColor = option.colors[0]
      styles.color = option.colors[0]
    }

    if (bgImgs.length) {
      styles.backgroundImage = bgImgs.join(',')
      styles.backgroundSize = 'cover'
      styles.backgroundPosition = 'center'
    }

    return styles
  }

  return {
    getStyles
  }
}
