<template>
  <section class="bg-surface-raised overflow-y-auto">
    <BaseButton
      look="ghost"
      class="p-0 size-[3em] fixed top-4 right-4 z-40"
      :aria-label="$t('close')"
      @click="close"
    >
      <XMarkIcon class="size-6" aria-hidden="true" />
    </BaseButton>
    <div class="grid gap-6 py-6 text-center">
      <h2 class="beta">
        {{ $t('images') }}
      </h2>
      <div class="flex flex-wrap gap-y-8 gap-x-4 overflow-y-auto justify-center *:flex *:justify-center *:items-center">
        <div v-for="image in images" :key="image.url">
          <img
            :src="image.url"
            class="max-h-[90vh] max-w-full"
            loading="lazy"
            :alt="image.alt"
          >
        </div>
      </div>
      <div>
        <BaseButton look="outline" @click="close">
          <ChevronLeftIcon aria-hidden="true" class="size-6" /><span>{{ $t('backToProduct') }}</span>
        </BaseButton>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { XMarkIcon, ChevronLeftIcon } from '@heroicons/vue/24/outline'
import { useProductStore } from '~/stores/product'

const { currentProductImages } = useProductStore()
const { $emitter } = useNuxtApp()
const close = () => {
  $emitter.emit('lightbox:open', false)
}
const images = computed(() => currentProductImages)
</script>
