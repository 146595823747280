<template>
  <section class="bg-grey-950 dark:bg-grey-50 overflow-y-auto">
    <BaseButton
      look="outline-invert"
      class="text-white p-0 size-[3em] fixed top-4 right-4 z-40"
      :aria-label="$t('close')"
      @click="close"
    >
      <XMarkIcon class="size-6" aria-hidden="true" />
    </BaseButton>
    <div class="grid gap-6 py-6 text-center">
      <h2 class="beta">
        {{ $t('video') }}
      </h2>
      <div class="flex items-center justify-center">
        <iframe
          :src="parsedUrl() + '?controls=1&autoplay=1'"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          class="aspect-video w-full max-w-screen-lg shadow-lg"
        />
      </div>
      <div>
        <BaseButton look="outline-invert" class="text-white" @click="close">
          <ChevronLeftIcon aria-hidden="true" class="size-6" /><span>{{ $t('backToProduct') }}</span>
        </BaseButton>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { XMarkIcon, ChevronLeftIcon } from '@heroicons/vue/24/outline'
import { useProductStore } from '~/stores/product'

const { currentProduct } = storeToRefs(useProductStore())
const { $emitter } = useNuxtApp()
const close = () => {
  $emitter.emit('video-modal:open', false)
}
const parsedUrl = (): string => {
  let url = currentProduct.value?.video?.url || ''
  if (url.includes('watch')) {
    url = url.replace('watch?v=', 'embed/').replace('youtube', 'youtube-nocookie')
  }
  return url
}
</script>
