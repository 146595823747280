<template>
  <div v-if="allProducts.length" class="overflow-hidden lg:py-10 py-8">
    <div class="container">
      <div class="flex flex-wrap gap-x-8 gap-y-6 mb-6">
        <h2 class="beta">
          Lisätarvikkeet
        </h2>

        <div class="flex flex-wrap gap-3">
          <button
            type="button"
            class="transition-colors whitespace-nowrap bg-surface hover:bg-grey-100 text-secondary font-medium inline-flex gap-2 py-2 px-4 rounded-full items-center hover:no-underline data-[selected=true]:bg-black data-[selected=true]:text-white dark:data-[selected=true]:bg-grey-950 dark:data-[selected=true]:text-black"
            :data-selected="selectedFilter === null"
            @click="selectedFilter = null"
          >
            Kaikki <span class="opacity-70 font-normal" v-text="allProducts.length" />
          </button>
          <button
            v-for="group in groups"
            :key="group.id"
            type="button"
            class="transition-colors whitespace-nowrap bg-surface hover:bg-grey-100 text-secondary font-medium inline-flex gap-2 py-2 px-4 rounded-full items-center hover:no-underline data-[selected=true]:bg-black data-[selected=true]:text-white dark:data-[selected=true]:bg-grey-950 dark:data-[selected=true]:text-black"
            :data-selected="selectedFilter === group.id"
            @click="selectedFilter = selectedFilter === group.id ? null : group.id"
          >
            {{ group.name }} <span class="opacity-70 font-normal" v-text="group.productCount" />
          </button>
        </div>
      </div>

      <div v-auto-animate class="grid grid-cols-[repeat(auto-fill,_minmax(min(380px,100%),1fr))] gap-4">
        <AdditionalProductTile
          v-for="tileProduct in productsShown"
          :key="tileProduct.slug"
          :product="tileProduct"
          class="w-auto h-full max-h-[166px] shadow-sm"
        />
      </div>
      <div class="flex justify-center mt-6 md:justify-start md:mt-4">
        <BaseButton v-if="filteredProducts.length > numberOfHitsShown" look="outline" @click="++pageNumber">
          Näytä lisää
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { vAutoAnimate } from '@formkit/auto-animate'
import AdditionalProductTile from './AdditionalProductTile.vue'
import { attributesForProductTile } from '~/helpers/instantsearch'
import type { ProductTileAlgolia, VehicleAdditionalProducts } from '~/types/product'

const props = defineProps({
  additionalProducts: {
    type: Object as () => VehicleAdditionalProducts,
    required: true
  }
})

const HITS_PER_PAGE_MOBILE = 3
const HITS_PER_PAGE_DESKTOP = 9

const allProducts: Ref<ProductTileAlgolia[]> = ref([])

const pageNumber = ref(1)
const numberOfHitsPerPage = ref(HITS_PER_PAGE_MOBILE)
const numberOfHitsShown = computed(() => numberOfHitsPerPage.value * pageNumber.value)

onMounted(() => {
  if (window !== undefined && window.innerWidth >= 1024) {
    numberOfHitsPerPage.value = HITS_PER_PAGE_DESKTOP
  }
})

const groupNames: { [K in keyof VehicleAdditionalProducts]: string } = {
  bags: 'Laukut',
  safety: 'Suojaus',
  comfort: 'Mukavuus',
  work: 'Työlaitteet',
  other: 'Muut'
}

const selectedFilter: Ref<keyof VehicleAdditionalProducts | null> = ref(null)
const selectedItemIds = computed(() => selectedFilter.value ? props.additionalProducts[selectedFilter.value as keyof VehicleAdditionalProducts] : Object.values(props.additionalProducts).flat())

const filteredProducts = computed(() => allProducts.value.filter(product => selectedItemIds.value?.includes(product.itemId)))
const productsShown = computed(() => filteredProducts.value.slice(0, numberOfHitsShown.value))

// Reset page number when filter changes
watch(() => selectedFilter.value, () => {
  pageNumber.value = 1
})

// Groups
const groups = computed(() => (Object.keys(props.additionalProducts) as (keyof VehicleAdditionalProducts)[]).map(groupId => ({
  id: groupId,
  name: groupNames[groupId] || '',
  productCount: allProducts.value.filter(product => (props.additionalProducts?.[groupId as keyof VehicleAdditionalProducts] || []).includes(product.itemId)).length
})).filter(group => group.productCount > 0 && group.name).sort((a, b) => Object.keys(groupNames).indexOf(a.id) > Object.keys(groupNames).indexOf(b.id) ? 1 : -1))

// Fetch products
const { data } = await useAsyncAlgoliaSearch({
  key: 'vehicleAdditionalProducts',
  query: '',
  requestOptions: {
    filters: Object.values(props.additionalProducts).flat().map(id => `itemId:${id}`).join(' OR '),
    distinct: true,
    attributesToRetrieve: attributesForProductTile,
    attributesToHighlight: []
  }
})
allProducts.value = data.value?.hits as ProductTileAlgolia[]
</script>
