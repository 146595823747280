<template>
  <div class="w-full sm:w-auto relative">
    <BaseButton
      data-test="add-to-quote-cart"
      data-jest="add-to-quote-cart"
      :look
      class="w-full text-lg"
      @click="addToQuoteCart"
    >
      {{ $t('requestQuote') }}
    </BaseButton>
    <ClientOnly>
      <span
        :class="{ hidden: amountInQuoteCart < 1 }"
        class="ring-2 ring-surface w-6 h-6 absolute -top-2 -right-3 bg-red-600 text-white rounded-full text-xs font-semibold grid place-items-center"
        :aria-label="$t('amountInQuoteCart', amountInQuoteCart)"
        :title="$t('amountInQuoteCart', amountInQuoteCart)"
      >
        {{ amountInQuoteCart }}
      </span>
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import BaseButton from '~/components/BaseButton.vue'
import { useQuoteCartStore } from '~/stores/quoteCart'
import { useProductStore } from '~/stores/product'

defineProps({
  look: {
    type: String,
    required: false,
    default: 'secondary'
  }
})

const productStore = useProductStore()
const quoteCartStore = useQuoteCartStore()

const product = computed(() => productStore.currentProduct)

const amountInQuoteCart = computed(() => quoteCartStore.getQuoteCartItemBySku(product.value?.sku ?? '')?.amount || 0)

const addToQuoteCart = () => {
  if (product.value) {
    quoteCartStore.addToQuoteCart(product.value)
  }
}
</script>
