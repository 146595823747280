<template>
  <div class="option-set">
    <div class="mb-4 flex items-center justify-between md:justify-start gap-x-4 gap-y-1 flex-wrap">
      <div><strong>{{ optionSet.attributeLabel }}</strong>: {{ selectedValue }}</div>
      <!-- <button v-if="canPreview" class="text-sm md:text-xs text-red-800 hover:text-rose" @click="isPreviewOpen = true">
        <span class="flex items-center gap-1"><info-icon size="1.4x" />Lue lisää</span>
      </button> -->
    </div>
    <!-- <base-modal v-if="selectedSwatch" :open="isPreviewOpen" @close="isPreviewOpen = false">
      <div class="[&:has(+div)]:hidden h-96 animate-fadepulse bg-grey-50" aria-label="Pieni hetki, lisätietoja ladataan." />
      <lazy-product-product-options-option-details v-if="isPreviewOpen" :swatch="selectedSwatch" class="md:-mb-4" />
    </base-modal> -->
    <div class="flex gap-4 flex-wrap" :class="{ '': isImageOption(optionSet.attributeLabel) }">
      <component
        :is="getComponent(option.label)"
        v-for="option in options"
        :key="option.value"
        :option="option"
        @option-selection="selectOption(option)"
      />
    </div>
    <!-- <div v-if="hasCustomOption" class="mt-6">
      <div class="[&:has(+div)]:hidden inline-block h-[67px] md:h-[73px] w-full max-w-48 bg-gradient-to-r from-grey-200 to-transparent animate-fadepulse" aria-label="Pieni hetki, valintoja ladataan." />
      <lazy-product-product-options-custom-option v-if="hasCustomOption" :selected-option-value="selectedValue" :option-label="optionSet.attributeLabel" />
    </div> -->
  </div>
</template>

<script setup lang="ts">
import { useProductOption } from '~/composables/useProductOption'
// import BaseModal from '~/components/BaseModal.vue'
// import { InfoIcon } from '~/components/veke-ui/icons'
import TextOption from '~/components/product/product-options/option-types/TextOption.vue'
import ImageOption from '~/components/product/product-options/option-types/ImageOption.vue'
import type { ProductOptionSet, ProductOptionDetailed } from '~/types/product'

const props = defineProps<{ optionSet: ProductOptionSet }>()
const emit = defineEmits(['option-selection'])

/**
 * Emits option-selection event to parent. Detailed option as a payload.
 * @param option
 * @emits option-selection
 */
const selectOption = (option: ProductOptionDetailed) => {
  emit('option-selection', option)
}

/**
 * Swatch preview modal open state
 * @returns boolean
 */
// const isPreviewOpen = ref(false)

/**
 * Sorted options
 */
const options = computed(() => props.optionSet.options.slice().sort((a, b) => (a.value && b.value) ? a.value.replace('Vasen', 'A').localeCompare(b.value.replace('Vasen', 'A'), undefined, { numeric: true, sensitivity: 'base' }) : -1))

/**
 * Selected option
 * @returns ProductOptionDetailed|undefined
 */
const selectedOption = computed(() => props.optionSet.options.find(v => v.isSelected))

/**
 * Returns value from the selected option.
 * @returns string|undefined
 */
const selectedValue = computed(() => selectedOption.value?.value)

/**
 * Returns true, if selected value is 'Oma Koko' or 'Räätälöitävä'.
 * @returns boolean
 */
// const hasCustomOption = computed(() => ['Oma Koko', 'Räätälöitävä'].includes(selectedValue.value + ''))

/**
 * Selected swatch
 */
// const selectedSwatch = computed(() => selectedOption.value ? swatchStore.getSwatch(selectedOption.value?.label, selectedOption.value?.value) : null)

/**
 * Returns true, if swatch is found, and it has an image or a description text.
 * @return boolean
 */
// const canPreview = computed(() => selectedSwatch.value ? (selectedSwatch.value.imageFull || selectedSwatch.value.description?.json?.content[0]?.content?.some((c: any) => c.value !== '')) && props.optionSet.attributeLabel !== 'Väri' : false)

/**
 *
 */
const { getOptionComponent, isImageOption } = useProductOption()

const getComponent = (type: string) => {
  const key: string = getOptionComponent(type)
  const componentMap: { [key: string]: any } = {
    'text-option': TextOption,
    'image-option': ImageOption
  }
  return componentMap[key]
}
</script>

<style lang="scss">
.option-set .modal .modal-content {
  max-width: 582px;
  .btn {
    @apply bg-white active:bg-white;
  }
}
</style>
