<template>
  <div>
    <slot :props="props" />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  accordionClass: {
    type: [Object, String, Array],
    required: false
  },
  closedWrapperClass: {
    type: [Object, String, Array],
    required: false
  },
  openWrapperClass: {
    type: [Object, String, Array],
    required: false
  },
  buttonClass: {
    type: [Object, String, Array],
    required: false
  },
  openButtonClass: {
    type: [Object, String, Array],
    required: false
  },
  contentClass: {
    type: [Object, String, Array],
    required: false
  }
})
</script>
