<template>
  <section class="bg-grey-100">
    <div class="h-20 lg:hidden bg-grey-100 fixed top-0 inset-x-0 px-4 flex items-center">
      <h2 class="beta">
        {{ $t('threeDImage') }}
      </h2>
    </div>
    <BaseButton
      class="fixed top-4 lg:right-1/2 transform lg:translate-x-1/2 right-4 z-40 dark:text-grey-50 dark:btn-outline-invert"
      :aria-label="$t('close')"
      @click="close"
    >
      <XMarkIcon class="size-6" aria-hidden="true" /><span class="hidden lg:inline">{{ $t('close') }}</span>
    </BaseButton>
    <div class="pt-20 lg:pt-0 h-full">
      <iframe
        :src="pixmoorUrl"
        frameborder="0"
        scrolling="no"
        class="h-full w-full bg-white"
      />
    </div>
  </section>
</template>

<script setup lang="ts">
import { XMarkIcon } from '@heroicons/vue/24/outline'
import { useProductStore } from '~/stores/product'

const { currentProduct } = storeToRefs(useProductStore())
const { $emitter } = useNuxtApp()
const close = () => {
  $emitter.emit('pixmoor-image:open', false)
}
const pixmoorUrl = currentProduct.value?.pixmoorUrl ? currentProduct.value.pixmoorUrl + 'embed.html' : ''
</script>
