<template>
  <div v-if="storeAvailabilityInfo">
    <div class="flex flex-col gap-3 items-start text-sm *:inline-flex *:gap-2 *:items-center">
      <div>
        <component
          :is="icons[storeAvailabilityInfo.icon]"
          class="size-6 shrink-0"
          :class="storeAvailabilityInfo.cssClasses"
        />
        <strong data-jest="productStock-statusText">
          {{ storeAvailabilityInfo.availabilityText }}
        </strong>
      </div>
      <!-- Supplier stock status -->
      <div v-if="supplierAvailability">
        <CheckCircleIcon v-if="supplierAvailability.isAvailable" class="size-6 shrink-0 text-green-500" />
        <XCircleIcon v-else class="size-6 shrink-0 text-red-700" />
        <strong>
          {{ supplierAvailability.isAvailable ? 'Saatavissa maahantuojalta' : 'Ei saatavissa maahantuojalta' }}
        </strong>
      </div>
      <div v-if="isSaleable">
        <CubeIcon class="size-6 text-default shrink-0" />
        <span data-jest="productStock-deliveryPhrase">{{ storeAvailabilityInfo.deliveryPhrase }}</span>
      </div>
      <button v-if="isSaleable" class="text-left underline underline-offset-4 decoration-black/30 hover:decoration-black" @click="isShippingCostsModalOpen = true">
        <TruckIcon class="size-6 text-default shrink-0" />
        <span data-jest="productStock-shippingCost">Toimitus alkaen {{ eur(lowestShippingPrice) }}</span>
      </button>
      <button class="text-left underline underline-offset-4 decoration-black/30 hover:decoration-black" @click="isStoreModalOpen = true">
        <BuildingStorefrontIcon class="size-6 text-default shrink-0" />
        <span data-jest="productStock-storeAvailabilityText">{{ storeAvailabilityText }}</span>
      </button>
    </div>
    <ProductStoreAvailabilityModal :is-open="isStoreModalOpen" :product="product" @close="isStoreModalOpen = false" />
    <BaseModal :open="isShippingCostsModalOpen" @close="isShippingCostsModalOpen = false">
      <h3 class="beta mb-6">
        Toimitustavat
      </h3>
      <div class="grid -mx-3 md:-mx-3">
        <dl v-for="cost in formattedShippingCosts" :key="cost.id" class="flex flex-wrap gap-4 odd:bg-grey-50 px-3 py-2">
          <dt :data-jest="`shippingCostsInfo-label-${cost.id}`" class="flex flex-col gap-1 flex-1 leading-snug">
            <span>{{ cost.label }}</span>
            <span v-if="cost.description" class="text-secondary text-xs">{{ cost.description }}</span>
          </dt>
          <dd class="price" :data-jest="`shippingCostsInfo-price-${cost.id}`">
            {{ eur(cost.price) }}
          </dd>
        </dl>
      </div>
    </BaseModal>
  </div>
</template>

<script setup lang="ts">
import { CubeIcon, TruckIcon, BuildingStorefrontIcon } from '@heroicons/vue/24/outline'
import { CheckCircleIcon, ClockIcon, XCircleIcon } from '@heroicons/vue/24/solid'
import { useStock } from '~/composables/useStock'
import { useShipping } from '~/composables/useShipping'
import ProductStoreAvailabilityModal from '~/components/stock/ProductStoreAvailabilityModal.vue'
import type { ProductAlgolia, EcomAvailabilityInfo } from '~/types/product'
import { eur } from '~/helpers/filters'

const props = defineProps<{
  product: ProductAlgolia
}>()

const icons = reactive({
  'check-circle-icon': CheckCircleIcon,
  'clock-icon': ClockIcon,
  'x-circle-icon': XCircleIcon
})

const { getEcomAvailabilityInfo, storeAvailabilityText } = useStock()
const { getShippingCosts, getLowestMaxShippingPrice } = useShipping()

const isSaleable = computed(() => ['inStock', 'backorder'].includes(props.product?.stock?.stockStatus || ''))
const formattedShippingCosts = computed(() => getShippingCosts(props.product.shippingCosts))
const storeAvailabilityInfo: ComputedRef<EcomAvailabilityInfo> = computed(() => getEcomAvailabilityInfo(props.product.stock, props.product.erpinfo as ProductAlgolia['erpinfo']))
const lowestShippingPrice = computed(() => getLowestMaxShippingPrice(formattedShippingCosts.value.filter(sp => sp.group !== 'StorePickup')))
const supplierAvailability = computed(() => props.product.stock.supplierAvailability)
const isStoreModalOpen = ref(false)
const isShippingCostsModalOpen = ref(false)

// function toggleShippingCostsModal() {
//   isShippingCostsModalOpen.value = !isShippingCostsModalOpen.value
// }

// function toggleStoreModal() {
//   isStoreModalOpen.value = !isStoreModalOpen.value
// }
</script>
