<template>
  <div>
    <div :data-on-sale="product.finalPrice.isOnSale" :data-labels="product.labelTags">
      <div class="min-w-0 grid lg:grid-cols-12 gap-8 xl:gap-12 lg:container mb-12">
        <div class="min-w-0 lg:col-span-6 xl:col-span-7">
          <ProductMediaGallery
            :discount-percent="discountPercent"
            :labels="product.labels || []"
            :images="product.images.slice(0, 24)"
            :pixmoor-url="product.pixmoorUrl"
            :has-video="!!product?.video?.url"
            :has-review-images="!!product?.reviewImages?.length"
          />
        </div>
        <div class="lg:col-span-6 xl:col-span-5 relative">
          <div class="container lg:px-0 lg:mx-0 relative">
            <ProductLabels :labels="product?.labels || []" class="mb-4" />
            <div class="flex gap-4">
              <div class="grid gap-2 flex-1 mb-6">
                <ProductBrandLink v-if="product?.marketing?.productManufacturer" class="font-semibold" :manufacturer="product.marketing.productManufacturer.value" />
                <h1 class="sr-only">
                  {{ product.name.variation || product.name.default }}
                </h1>
                <div class="beta" aria-hidden="true">
                  {{ product.name.default }}
                </div>
              </div>
              <AddToWishlist />
            </div>
            <div class="flex justify-between gap-4 mb-6">
              <div class="grid gap-6 flex-1 mt-2">
                <div class="grid gap-4">
                  <div>
                    <ProductPriceDisplay
                      class="text-lg"
                      :price="product.price"
                      :final-price="product.finalPrice"
                      show-discount-percent
                    />
                    <div class="mt-2 text-muted text-sm">
                      {{ eur(product.finalPrice.exclTax) }} alv. 0%
                    </div>
                  </div>
                  <div
                    v-if="priceDescription"
                    id="price-description"
                    class="text-sm text-balance"
                    v-html="priceDescription"
                  />
                </div>
                <div>
                  <button class="inline-flex items-center gap-2.5 group" @click="openReviews">
                    <ReviewStars class="h-5" :animate="reviewScore >= 4" :score="reviewScore" />
                    <span class="mt-0.5 text-sm a-link">
                      <template v-if="!reviewAmount">
                        {{ $t('writeAReview') }}
                      </template>
                      <template v-else>
                        {{ $t('amountOfReviews', reviewAmount) }}
                      </template>
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <div class="mb-6" v-html="product.description.short" />
            <ProductOptions v-if="!isSimpleProduct" :product="product" @option-change="setValidVariationSelected" />
            <div class="mt-6 flex flex-col md:flex-row gap-3">
              <AddToCart :can-add-to-cart="product.allowPurchase && isValidVariationSelected" class="flex-1" />
              <AddToQuoteCart v-if="product.allowQuoteRequest" class="flex-1" :look="!product.allowPurchase ? 'transactional' : 'secondary'" />
            </div>
            <ProductStock class="bg-grey-50 rounded mt-6 -mx-container p-container min-[390px]:mx-0 min-[390px]:p-4" :product="product" />
            <ProductSveaWidget :price="product.finalPrice.inclTax" class="mt-6" />
            <div class="mt-6">
              <p class="mb-2 font-semibold sr-only">
                Miksi valita meidät?
              </p>
              <ul class="list-reset grid gap-2 *:inline-flex *:gap-2 *:items-center">
                <li><CheckIcon aria-hidden="true" class="size-5 text-green-500" />Nopea toimitus</li>
                <li><CheckIcon aria-hidden="true" class="size-5 text-green-500" />Monipuoliset maksutavat</li>
                <li><CheckIcon aria-hidden="true" class="size-5 text-green-500" />30 vuoden kokemuksella</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="bg-grey-50 border-t pt-10 sm:pb-10">
      <div class="container grid lg:grid-cols-2 gap-y-6 gap-x-12 mb-12">
        <div>
          <h2 class="sr-only">
            Tuoteinfo
          </h2>
          <div class="gamma [&+div]:mt-4">
            {{ product.description.title || product.name.default }}
          </div>
          <ReadMore v-if="product.description.long">
            <div class="prose" v-html="product.description.long" />
          </ReadMore>
        </div>
        <div class="grid gap-6">
          <div v-if="phrases.length">
            <ul class="list-reset grid gap-4">
              <li v-for="phrase in phrases" :key="phrase" class="flex gap-3 wrap-balance font-semibold">
                <CheckCircleIcon class="size-6 shrink-0 text-green-500" />{{ phrase }}
              </li>
            </ul>
          </div>

          <ld-slot v-if="isVehicle" name="tuotekortti" />
        </div>
      </div>

      <div class="container">
        <AccordionGroup
          v-slot="group"
          class="-mx-container sm:mx-0 sm:border border-light divide-y divide-light"
          accordion-class="bg-surface-raised"
          button-class="p-5 text-base md:text-lg"
          content-class="p-5 pt-0"
        >
          <BaseAccordion id="product-technical-specs" v-bind="group.props">
            <template #button>
              <div class="flex items-center gap-3">
                <Bars3Icon aria-hidden="true" class="size-6" />Tekniset tiedot
              </div>
            </template>
            <ProductTechnicalSpecifications :product="product" />
          </BaseAccordion>
          <BaseAccordion v-if="product.measurements" id="product-measurements" v-bind="group.props">
            <template #button>
              <div class="flex items-center gap-3">
                <CubeIcon aria-hidden="true" class="size-6" />Mitat
              </div>
            </template>
            <ProductMeasurements :measurements="product.measurements" />
          </BaseAccordion>
          <BaseAccordion v-if="product.attachments && product.attachments.length > 0" id="product-attachments" v-bind="group.props">
            <template #button>
              <div class="flex items-center gap-3">
                <PaperClipIcon aria-hidden="true" class="size-6" />Ohjeet ja liitteet
              </div>
            </template>
            <ProductAttachments :attachments="product.attachments" />
          </BaseAccordion>
          <BaseAccordion id="product-delivery" v-bind="group.props">
            <template #button>
              <div class="flex items-center gap-3">
                <TruckIcon aria-hidden="true" class="size-6" />Verkkokaupan toimitustavat
              </div>
            </template>
            <DeliveryInfo />
          </BaseAccordion>
          <BaseAccordion v-if="hasDiscount && product.lowestSpecialPrice" id="product-price-history" v-bind="group.props">
            <template #button>
              <div class="flex items-center gap-3">
                <CurrencyEuroIcon aria-hidden="true" class="size-6" />Hintahistoria
              </div>
            </template>
            <ProductPriceHistory :lowest-special-price="product.lowestSpecialPrice" />
          </BaseAccordion>
          <BaseAccordion id="arvostelut" ref="reviewsAccordion" v-bind="group.props">
            <template #button>
              <div class="flex items-center gap-3">
                <StarIcon aria-hidden="true" class="size-6" />{{ reviewAmount > 0 ? $t('amountOfReviews', reviewAmount) : $t('writeAReview') }}
                <ReviewStars class="h-3" :score="reviewScore" /><div v-if="reviewAmount" class="text-xxs text-muted tracking-wider">
                  ({{ reviewAmount }})
                </div>
              </div>
            </template>
            <YotpoReviews />
          </BaseAccordion>
        </AccordionGroup>
      </div>
    </section>
    <section v-if="product.vehicleAdditionalProducts" class="bg-grey-50">
      <ProductAdditionalProducts :additional-products="product.vehicleAdditionalProducts" />
    </section>
    <div class="even:*:bg-grey-200 odd:*:bg-grey-100">
      <ProductTileCarousel
        v-if="product.relatedProducts && product.relatedProducts.length > 0"
        :item-ids="product.relatedProducts"
        carousel-title="Liittyvät tuotteet"
        search-key="relatedProducts"
      />
      <ProductTileCarousel
        v-if="product.synonymProducts && product.synonymProducts.length > 0"
        :item-ids="product.synonymProducts"
        carousel-title="Vaihtoehtoiset tuotteet"
        search-key="synonymProducts"
      />
    </div>
    <!-- <delivery-notice /> -->
    <TransitionGroup name="zoom-in">
      <LightBox v-if="isLightBoxOpen" data-scroll-lock="isLightBoxOpen" class="fixed inset-0 z-50 h-full w-full" />
      <VideoModal v-if="isVideoModalOpen" data-scroll-lock="isVideoModalOpen" class="fixed inset-0 z-50 h-full w-full" />
      <PixmoorImage v-if="isPixmoorImageOpen" data-scroll-lock="isPixmoorImageOpen" class="fixed inset-0 z-50 h-full w-full" />
      <ReviewImages v-if="isReviewImagesOpen" data-scroll-lock="isReviewImagesOpen" class="fixed inset-0 z-50 h-full w-full" />
    </TransitionGroup>
  </div>
</template>

<script setup lang="ts">
import { CheckIcon, Bars3Icon, PaperClipIcon, TruckIcon, CurrencyEuroIcon, StarIcon, CubeIcon, CheckCircleIcon } from '@heroicons/vue/24/outline'
import { useBreadcrumbStore } from '~/stores/breadcrumb'
import { useProduct } from '~/composables/useProduct'
import LightBox from '~/components/product/media-gallery/LightBox.vue'
import VideoModal from '~/components/product/media-gallery/VideoModal.vue'
import PixmoorImage from '~/components/product/media-gallery/PixmoorImage.vue'
import ReviewImages from '~/components/product/media-gallery/ReviewImages.vue'
import ProductTechnicalSpecifications from '~/components/product/ProductTechnicalSpecifications.vue'
import ProductMeasurements from '~/components/product/ProductMeasurements.vue'
import ProductAttachments from '~/components/product/ProductAttachments.vue'
import DeliveryInfo from '~/components/product/DeliveryInfo.vue'
import ProductPriceHistory from '~/components/product/ProductPriceHistory.vue'
import ProductStock from '~/components/stock/ProductStock.vue'
import ProductLabels from '~/components/product/ProductLabels.vue'
import ReviewStars from '~/components/product/ReviewStars.vue'
import ProductOptions from '~/components/product/ProductOptions.vue'
import type { ProductAlgolia, StockObject } from '~/types/product'
import { eur } from '~/helpers/filters'
import { usePrice } from '~/composables/usePrice'

const props = defineProps<{
  product: ProductAlgolia
}>()

const gtm = useGtm()
const breadcrumbStore = useBreadcrumbStore()
const { salesPhrases, getCategoryPath, discountValidUntilText, convertProductToGtmProduct } = useProduct()
const reviewsAccordion = ref(null)
const isValidVariationSelected = ref(true)
const hasDiscount = computed(() => props.product.finalPrice.inclTax < props.product.price.inclTax)
const stock: ComputedRef<StockObject | any> = computed(() => !Array.isArray(props.product.stock) && props.product.stock !== null ? props.product.stock : {})
const isSimpleProduct = computed(() => props.product.sku === props.product.parentSku)
const reviewAmount = computed(() => props.product.yotpo?.reviewAmount || 0)
const reviewScore = computed(() => props.product.yotpo?.reviewScore || 0)
const priceDescription = computed(() => hasDiscount.value ? discountValidUntilText(props.product) : '')
const phrases = computed(() => salesPhrases(props.product))
const isVehicle = computed(() => props.product?.categories?.some(cat => cat.slug === 'ajoneuvot'))

watch(
  () => props.product,
  (newProduct: ProductAlgolia, oldProduct: ProductAlgolia | undefined) => {
    // fire event only on client side
    if (import.meta.client) {
      // check that the product changes
      if (newProduct.sku !== oldProduct?.sku) {
        const gtmProduct = convertProductToGtmProduct(newProduct)
        // push to gtm
        gtm!.trackEvent({
          event: 'view_item',
          ecommerce: {
            items: [gtmProduct]
          }
        })
      }
    }
  },
  { immediate: true, deep: true }
)

// Logic to open reviews
const openReviews = () => {
  const accordion = reviewsAccordion.value as any
  if (accordion) {
    accordion.$el.scrollIntoView({ behavior: 'smooth' })
    // @TODO: fix this, not opening accordion
    accordion.open = true
  }
}

const setValidVariationSelected = (isValid: boolean) => {
  isValidVariationSelected.value = isValid
}

const isLightBoxOpen = ref(false)
const isVideoModalOpen = ref(false)
const isPixmoorImageOpen = ref(false)
const isReviewImagesOpen = ref(false)

const { getDiscountPercent } = usePrice()

const discountPercent = computed(() => {
  return getDiscountPercent(props.product.price.inclTax, props.product.finalPrice.inclTax)
})

const { $emitter } = useNuxtApp()

onMounted(() => {
  $emitter.on('lightbox:open', (isOpen) => {
    isLightBoxOpen.value = isOpen
  })
  $emitter.on('video-modal:open', (isOpen) => {
    isVideoModalOpen.value = isOpen
  })
  $emitter.on('pixmoor-image:open', (isOpen) => {
    isPixmoorImageOpen.value = isOpen
  })
  $emitter.on('reviewimages:open', (isOpen) => {
    isReviewImagesOpen.value = isOpen
  })
  // todo is this needed
  // const gtmProduct = convertProductToGtmProduct(props.product)
  // console.log(gtmProduct)

  // Set product page breadcrumb
  const breadcrumbs = getCategoryPath(props.product.categories)
  breadcrumbs.push({ slug: props.product.slug, value: props.product.name.default })
  breadcrumbStore.setCurrent(breadcrumbs)
})

const config = useRuntimeConfig()
useSeoMeta({
  title: props.product.name.default || '',
  ogTitle: props.product.name.default || '',
  ogUrl: `${config.public.BASE_URL}/product/${props.product.slug}`,
  ogImage: props.product.defaultVariationImage?.url || '',
  description: props.product.description?.short?.replace(/<\/?[^>]+(>|$)/g, '') || '',
  ogDescription: props.product.description?.short?.replace(/<\/?[^>]+(>|$)/g, '') || ''
})
useHead(() => ({
  link: [
    {
      rel: 'canonical',
      href: `${config.public.BASE_URL}/product/${props.product.slug}`
    }
  ]
}))
</script>
